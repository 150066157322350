var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "	<button class=\"a-button a-button--tertiary js-searchView__filters-clear--mobile\"><span class=\"a-button__inner\">Clear</span></button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"m-search-form__filter-text__inner\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"numberOfFilters") || (depth0 != null ? lookupProperty(depth0,"numberOfFilters") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"numberOfFilters","hash":{},"data":data,"loc":{"start":{"line":1,"column":48},"end":{"line":1,"column":69}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"filtersText") || (depth0 != null ? lookupProperty(depth0,"filtersText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filtersText","hash":{},"data":data,"loc":{"start":{"line":1,"column":70},"end":{"line":1,"column":87}}}) : helper)))
    + " applied </span>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"numberOfFilters") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":5,"column":8}}})) != null ? stack1 : "");
},"useData":true});